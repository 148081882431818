import {useEffect, useMemo, useRef, useState} from "react";
import eventEmitter from "../../../../eventEmitter";
import {Button, Sheet, SheetBody, Subtitle} from "@panwds/react-ui";
import {PANWDSForm, PANWDSInput, PANWDSCheckboxField} from "../../../../components/PANWDSElements";
import {FormControl, Grid} from "@material-ui/core";
import {Field} from "react-final-form";
import * as React from "react";
import {useTranslate} from "../../../../customHooks";
import {makeStyles} from "@material-ui/core/styles";
import {SaveButton} from "../../../../components";
import {OnChange} from "react-final-form-listeners";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles(() => ({
    sheetBody: {
        flexGrow: 1,
    },
    sheetBottom: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "1rem",
        borderTop: "1px solid #DADBDB",
        gap: '10px'
    },
    manageTrafficForm: {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    privateTraffic: {
        borderRadius: "4px",
        border: "1px solid var(--Gray-30, #DADBDB)",
        padding: "1rem"
    }
}));

const ManageEgressNatTrafficContainer = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [showAdditionalCidrs, setShowAdditionalCidrs] = useState(false);
    const [state, setState] = useState({
        item: {},
        callback: (values: any) => {
        }
    });

    const CIDRS = ['10.0.0.0/8', '172.16.0.0/12', '192.168.0.0/16'];

    useEffect(() => {
        eventEmitter.on('MANAGE.TRAFFIC.EGRESS.NAT', handleEgressNatTraffic);
        return () => {
            eventEmitter.off('MANAGE.TRAFFIC.EGRESS.NAT', handleEgressNatTraffic);
        }
    }, []);

    const handleEgressNatTraffic = ({item, callback}: { item: any, callback: any }) => {
        const additionalCidrs = item?.Prefixes?.PrivatePrefix.Cidrs.filter((cidr: any) => CIDRS.indexOf(cidr) === -1);
        setState({
            item: {
                ...item,
                Prefixes: {
                    ...item?.Prefixes,
                    PrivatePrefix: {
                        ...item?.Prefixes?.PrivatePrefix,
                        defaultCidrs: CIDRS.join(', '),
                        Cidrs: isEmpty(additionalCidrs) ? '' : additionalCidrs.join(', '),
                        additionalCidrsFlag: !isEmpty(additionalCidrs)
                    }
                }
            },
            callback
        });
        setShowAdditionalCidrs(!isEmpty(additionalCidrs));
        setIsOpen(true);
    };

    const closeSheet = () => {
        setState({
            item: {},
            callback: () => {
            }
        })
        setShowAdditionalCidrs(false);
        setIsOpen(false);
    };

    const save = (values: any) => {
        const Prefixes = {
            PrivatePrefix: {
                Cidrs: CIDRS
            }
        };
        if (values?.Prefixes?.PrivatePrefix?.Cidrs) {
            Prefixes.PrivatePrefix.Cidrs = [...Prefixes.PrivatePrefix.Cidrs, ...values?.Prefixes?.PrivatePrefix?.Cidrs.trim().split(',')];
        }
        values.Prefixes = Prefixes;
        state.callback({...values});
        closeSheet();
    }

    const validate = (values: any) => {
        let errors: any = {};
        // To validate the cidrs
        if (values?.Prefixes?.PrivatePrefix?.additionalCidrsFlag && !values?.Prefixes?.PrivatePrefix?.Cidrs) {
            errors = {
                ...errors,
                Prefixes: {
                    ...errors?.Prefixes,
                    PrivatePrefix: {
                        ...errors?.Prefixes?.PrivatePrefix,
                        Cidrs: translate("validation.required")
                    }
                }
            }
        }
        return errors;
    }

    const SaveToolbar = (toolbarProps: any) => {
        const {pristine} = toolbarProps;
        return (
            <div className={classes.sheetBottom}>
                <Button appearance={"secondary"}
                        onClick={() => closeSheet()}>{translate("resources.integrations.close")}</Button>

                <SaveButton
                    appearance="primary"
                    size="md"
                    label={translate("resources.integrations.save")}
                    redirect={false}
                    submitOnEnter={true}
                    disabled={pristine}
                    {...toolbarProps}
                />
            </div>
        );
    }

    return (
        <Sheet
            onClose={() => closeSheet()}
            title={translate(`resources.firewalls.fields.EgressNAT.ManageTrafficAndEgressNat`)}
            isOpen={isOpen}
            position="md"
            showMask
        >
            <PANWDSForm
                onSubmit={save}
                validate={validate}
                toolbar={<SaveToolbar/>}
                initialValues={state.item}
                className={classes.manageTrafficForm}
                key={"manage-private-and-public-traffic-address"}>

                <SheetBody isLoading={false} addClassName={classes.sheetBody}>

                    <Grid container style={{width: 'auto'}}>

                        <Grid item xs={12} className="tw-pb-4">
                            <FormControl fullWidth>
                                <Field
                                    name="EndpointId"
                                    readOnly
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    title={translate(`resources.firewalls.fields.EndpointId`)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className="tw-pb-4">
                            <FormControl fullWidth>
                                <Field
                                    name="EgressNATEnabled"
                                    // @ts-ignore
                                    component={PANWDSCheckboxField}
                                    label={translate(`resources.firewalls.fields.EgressNAT.PerformEgressNAT`)}
                                    type="checkbox"
                                    dataMetrics={"cloudngfw-firewall-edit-manage-egress-nat-traffic-perform-egress-nat"}
                                    muted={translate(`resources.firewalls.fields.EgressNAT.PerformEgressNATMutedText`)}
                                />
                            </FormControl>
                        </Grid>

                        <div className={classes.privateTraffic}>

                            <Subtitle as={"div"} size={"sm"} appearance={"semibold"} addClassName="tw-pb-4">
                                {translate(`resources.firewalls.fields.EgressNAT.PrivateTrafficRange`)}
                            </Subtitle>

                            <Grid item xs={12} className="tw-pb-4">
                                <FormControl fullWidth>
                                    <Field
                                        name="Prefixes.PrivatePrefix.defaultCidrs"
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        readOnly
                                        title={translate(`resources.firewalls.fields.EgressNAT.IanaRfc`)}
                                        note={translate(`resources.firewalls.fields.EgressNAT.IanaRfcMutedText`)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} className="tw-pb-4">
                                <FormControl fullWidth>
                                    <Field
                                        name="Prefixes.PrivatePrefix.additionalCidrsFlag"
                                        // @ts-ignore
                                        component={PANWDSCheckboxField}
                                        label={translate(`resources.firewalls.fields.EgressNAT.AddAdditionalPrefixes`)}
                                        type="checkbox"
                                        dataMetrics={"cloudngfw-firewall-edit-manage-egress-nat-traffic-additional-egress-nat"}
                                        muted={translate(`resources.firewalls.fields.EgressNAT.AddAdditionalPrefixesMutedText`)}
                                    />
                                    <OnChange name={"Prefixes.PrivatePrefix.additionalCidrsFlag"}>
                                        {(selected: any) => setShowAdditionalCidrs(selected)}
                                    </OnChange>
                                </FormControl>
                            </Grid>

                            {showAdditionalCidrs && <Grid item xs={12} className="tw-pb-4">
                                <FormControl fullWidth>
                                    <Field
                                        name="Prefixes.PrivatePrefix.Cidrs"
                                        required
                                        // @ts-ignore
                                        component={PANWDSInput}
                                        defaultValue={''}
                                        title={translate(`resources.firewalls.fields.EgressNAT.AdditionalPrefixes`)}
                                        note={translate(`resources.firewalls.fields.EgressNAT.AdditionalPrefixesMutedText`)}
                                    />
                                </FormControl>
                            </Grid>}

                        </div>
                    </Grid>
                </SheetBody>
            </PANWDSForm>
        </Sheet>
    );
};

const handleManageEgressNatTraffic = ({item, callback}: { item: any, callback: any }) => {
    eventEmitter.emit('MANAGE.TRAFFIC.EGRESS.NAT', {item, callback})
}

export {ManageEgressNatTrafficContainer, handleManageEgressNatTraffic};
