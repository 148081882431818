import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useCallback, useEffect, useState, Fragment } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {Accordion, Button as PANWDSButton} from "@panwds/react-ui";
import { PANTitle, SaveButton, toast } from "../../components";
import {useTranslate} from '../../customHooks';
import {
    PANWDSForm, PANWDSBreadcrumbs
} from '../../components/PANWDSElements'
import { ApplicationConfigManager } from "../../types";
import { usePermissions } from '../../customHooks';
import isEmpty from "lodash/isEmpty";
import {useAppDispatch} from "../../app/hooks";
import {ReduxActions, ReduxResources} from "../../redux";
import {FirewallCreateComponent} from "./components";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    flexDirection: "row",
  },
  toolbar: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'end',
    '-webkit-justify-content': 'flex-end',
    alignItems: 'start',
    boxSizing: 'border-box',
    padding: '10px 0',
    minHeight: 'initial',
  },
  iconedParagraph: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    gap: '10px',
  },
    firewallAccordion: {
      '& li[data-testid="accordion-panel"]': {
          marginBottom: "1rem"
      }
    }
}));

const FirewallCreate = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const classes = useStyles();
    const {permissions} = usePermissions();
    const dispatch = useAppDispatch();

    const [submitting, setSubmitting] = useState<boolean>(false);

    const save = useCallback(
        async (values) => {
            const payload = {...values};
            if (!permissions?.CreateFirewall) {
                return;
            }

            if (!payload?.['Tags'].length) {
                delete payload['Tags']
            }

            if (payload['EndpointMode'] === "ServiceManaged") {
                payload['SubnetMappings'] = payload['Subnet'].map((v: string) => ({SubnetId: v})).filter((v: any) => v !== undefined);
                delete payload['AvailabilityZone'];
                delete payload['Subnet']
            } else {
                payload['SubnetMappings'] = payload['AvailabilityZone'].map((v: string) => ({AvailabilityZone: v}));
                delete payload['AvailabilityZone'];
            }
            if (payload['LinkId']) {
                payload['LinkId'] = payload.LinkId.split(" ")[0];
            }

            if (payload['CMLinkId']) {
                payload['LinkId'] = payload['CMLinkId'];
                delete payload['CMLinkId'];
            }

            if (payload['PolicyManaged']) {
                delete payload['PolicyManaged'];
            }
            payload["EgressNAT"] = {
                Enabled: false,
            }
            if (payload['EgressNatEnable']) {
                payload["EgressNAT"].Enabled = true;
                payload["EgressNAT"].Settings = {
                    IPPoolType: "AWSService" // 'AWSService' | 'BYOIP', defaults to 'AWSService'
                }
            }
            delete payload['EgressNatEnable'];
            setSubmitting(true);
            try {
                await dispatch(ReduxActions.create({resource: ReduxResources.FIREWALL})({data: payload})).unwrap()
                    .then(() => {
                        history.goBack();
                    })
                    .catch((response) => {
                        toast.error(response?.error, { toastId: "firewalls-create" });
                    });
            } catch (error: any) {
                toast.error(error?.error, {toastId: "firewalls-create"});
                return;
            } finally {
                setSubmitting(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [history, permissions]
    );

    interface FormValues {
        FirewallName: string;
        Description: string;
        VpcId: string;
        EndpointMode: string;
        AvailabilityZone?: any;
        Subnet?: any;
        RuleStackName?: string;
        ServiceManaged?: any;
        EgressNatEnable?: string;
        PublicIPs?: string;
    }

    const validate = (values: FormValues) => {
        //@ts-ignore
        const errors: FormValues = {};
        if (!values.EndpointMode) {
            errors.EndpointMode = translate("validation.required");
        }
        if (values.EndpointMode === "ServiceManaged") {
            if (values.ServiceManaged && values.ServiceManaged.length > 0) {
                values.ServiceManaged.map((sm: any, index: number) => {
                    errors.ServiceManaged = [];
                    if (isEmpty(sm.AccountId)) errors.ServiceManaged[index] = {...errors.ServiceManaged[index], AccountId: translate("validation.required")}
                    if (isEmpty(sm.Subnet)) errors.ServiceManaged[index] = {...errors.ServiceManaged[index], Subnet: translate("validation.required")};
                    if (isEmpty(sm.VpcId)) errors.ServiceManaged[index] = {...errors.ServiceManaged[index], VpcId: translate("validation.required")};
                })
                if (isEmpty(errors.ServiceManaged)) {
                    delete errors.ServiceManaged;
                }
            }
            // TODO: Remove this for phase 2
            if (!values.Subnet || isEmpty(values.Subnet)) {
                errors.Subnet = translate("validation.required")
            }
        }
        if (values.EndpointMode === "CustomerManaged") {
            if (!values.AvailabilityZone || isEmpty(values.AvailabilityZone)) {
                errors.AvailabilityZone = translate("validation.required")
            }
        }
        return errors;
    }

    const FirewallCreateToolbar = (toolbarProps: any) => {
        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    size="md"
                    appearance="secondary"
                    disabled={submitting}
                    onClick={() => {
                        history.goBack();
                    }}
                    dataMetrics="cloudngfw-firewall-create-cancel"
                >
                    Cancel
                </PANWDSButton>
                <SaveButton
                    appearance="primary"
                    size="md"
                    redirect={false}
                    submitOnEnter={true}
                    loading={submitting}
                    disabled={!permissions?.CreateFirewall}
                    {...toolbarProps}
                    dataMetrics="cloudngfw-firewall-create-save-button"
                />
            </div>
        )
    };

return (
    <>
        <PANTitle divider={false}/>
        <PANWDSBreadcrumbs
            mapping={{
                ngfirewalls: `${translate(`resources.firewalls.name`)} [ ${ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionDisplayName} ]`,
                create: translate(`resources.firewalls.fields.CreateButton`),
            }}
        />
        <PANTitle
            title={translate(`resources.firewalls.create.title`)}
            divider
        />

        <PANWDSForm
            onSubmit={save}
            toolbar={<FirewallCreateToolbar/>}
            validate={validate}
            style={{margin: 16}}
        >
            <Accordion addClassName={classes.firewallAccordion}
                       allowMultipleOpen
                       allowNoneOpen
                       onChangeOpenPanels={function Ga() {
                       }}
            >
                <FirewallCreateComponent.General/>
                <FirewallCreateComponent.PolicyManagement/>
                {process.env.REACT_APP_ENABLE_EGRESS_NAT === "true" && <FirewallCreateComponent.EgressNat/>}
                <FirewallCreateComponent.EndpointManagement/>
            </Accordion>

        </PANWDSForm>
    </>
  );
};

export default withRouter(FirewallCreate);
